@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
   font-family: "Inter", sans-serif !important;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
/* ::-webkit-scrollbar {
    display: none;
} */

/* Hide scrollbar for IE, Edge, and Firefox */
/* * {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: Rubik;
}

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.blinking-cursor {
    position: relative;
}

.blinking-cursor::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: white;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.text-2xs {
    font-size: 0.6rem;
    line-height: 0.9rem;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.main {
    background: linear-gradient(to bottom, #000000, #0a192f);
}

.hero-section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered-text {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: inherit;
}

.team {
    position: relative;
}
.container {
    z-index: 1;
}

.features {
    position: relative;
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-section h2 {
    font-size: 3.5rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 1rem;
}

.hero-section h3 {
    font-size: 2rem;
    color: #c4c4c4;
    margin-bottom: 2rem;
}
.hero-section .btn {
    display: inline-block;
    background-color: #ffffff;
    color: #0a192f;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border-radius: 0.25rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(10, 25, 47, 0.15);
}

.hero-section .btn:hover {
    background-color: #2980b9;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(41, 128, 185, 0.3);
}
.services-section {
    position: relative;
}

.services-section::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 2px;
    background-color: transparent; 
}

.section-title {
    position: relative;
    z-index: 1;
    color: #f2f2f2; 
    margin-bottom: 20px; 
}
.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes streak {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.streak-effect {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    animation: streak 1s linear forwards;
}

.text-primary {
    color: #00e3a0;
}

.text-primary {
    color: #00e3a0;
}

.step {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    transition: padding-left 0.3s ease;
}

.step.active {
    padding-left: 35px;
}

.step.active::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 100%;
    width: 4px;
    background: linear-gradient(to bottom, transparent 0%, #00e3a0 100%);
}
.icon {
    transition: all 0.3s ease-in-out;
}

.icon.growing {
    transform: scale(1.2); 
}

.icon-container {
    border-radius: 50%;
    padding: 5px;
    transition: box-shadow 0.3s ease-in-out;
}

.icon-container.selected {
    box-shadow: 0px 0px 5px #00e3a0;
}

.text-gray-400 {
    color: #ccc;
}

.container {
    max-width: 960px;
}

@keyframes comet-grow {
    0% {
        transform: scale(1);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
} */

/* Comet styles */
/* .comet {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5)
    );
    animation: comet-grow 1.5s infinite;
    opacity: 0;
}

.comet.active {
    animation-play-state: running;
    opacity: 1;
}
@keyframes selector-grow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
} */

/* Selector styles */
/* .selected .icon {
    animation: selector-grow 1s infinite;
} */

/* For the container of the Features section */
/* .container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
} */

/* For the feature items */
/* .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature-item h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.feature-item p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.feature-item a {
    font-size: 16px;
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
}
.feature-image {
    height: auto;
    width: 100%;
}
.feature-item a:hover {
    text-decoration: underline;
} */

/* For the "View All Features" button */
/* .view-all-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 0;
    margin-bottom: 1rem;
} */


.ellipsis_class {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipsis_class_oneline {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pagination_div .pagination {
    display: flex;
    gap: 10px;
    justify-content: end;
    margin-top: 2rem;
}
.ellipises_heading {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.quill .ql-container.ql-snow {
    height: 150px;
    overflow: auto;
}
.react-tel-input input {
    width: 100% !important;
    height: 40px !important;
}
.event_card:hover .hidden_card {
 display: block !important;
}
.leaflet-popup-content {
    width: 160px !important;
    margin: 15px 15px;
}
.profile_map .leaflet-popup-content {
    width: 250px !important;
}
.react-datepicker__close-icon::after {
    line-height: 0 !important;
}
.hidden_card {
    display: none;
}
.line_1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.p_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text_3line {
    display: -webkit-box;
    -webkit-line-clamp: 3 ;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.line_6 {
    display: -webkit-box;
    -webkit-line-clamp: 6 ;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.react-datepicker-wrapper {
    width: 100%;
}
.banner_button .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: #ffffffc7 !important;
    font-size: 48px !important;
    font-weight: 600 !important;
    text-shadow: 0px 0px 1px #000000 !important;
}
.banner_button .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    color: #ffffffc7 !important;
    font-size: 48px !important;
    font-weight: 600 !important;
    text-shadow: 0px 0px 1px #000000 !important;
}
.banner_home .slick-arrow {
    display: none !important;
}
.add_page .css-13cymwt-control {
    border-radius: 0px !important;
    background: #f9f9f9 !important;
}
.sidebar_header {
    position: relative;
    margin-left: 1.5rem;
}
.sidebar_header:after {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    background: #fff;
    border-left: 25px solid #d7bdbd00;
    border-right: 0px solid #7c6b6b00;
    border-top: 65px solid #134896;
    width: 0px;
    height: 0px;
}
.claimbtn2:hover .claimbtn_data2 {
 display: block;
 transition: 0.5s;
}
.claimbtn_data2 {
    display: none;
    transition: 0.5s;
}

.claimbtn:hover .claimbtn_data {
    display: block;
    transition: 0.5s;
   }
   .claimbtn_data {
       display: none;
       transition: 0.5s;
   }
.search_all .react-datepicker-wrapper {
    width: 40%;
}
[type='checkbox']:checked {
    background-image:url('assets/images/checked.svg') !important;
}
[type='checkbox']:checked {
    background-size: 0.55em 0.55em !important;
}
.slide_card .swiper.swiper-initialized.swiper-horizontal.mySwiper.swiper-backface-hidden {
    padding-bottom: 15px;
}
.banner_home ul.slick-dots {
    position: absolute;
    bottom: 30px;
    left: 0px;
    padding-left: 2rem;
    z-index: 9;
}
.banner_home .slick-dots li {
    margin: 0 3px !important;
}
.banner_home .slick-dots li button:before {
    font-size: 14px;
}
.banner_home .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #E6C045;
}
.banner_home .slick-dots li button:before {
    color: #EAEAEA;
}
.cards_bg .slick-slide {
    opacity: 0.2;
}
.cards_bg .slick-active {
    opacity: unset !important;
}
.cards_bg .slick-dots {
    bottom: -55px !important;
}
.cards_bg .slick-prev {
    left: 39% !important;
    bottom: -65px !important;
    top: unset !important;
    z-index: 9;
}
.cards_bg .slick-next {
    right: 39% !important;
    bottom: -65px !important;
    top: unset !important;
    z-index: 9;
}
.cards_bg .slick-dots li {
    margin: 0 3px !important;
}
.cards_bg .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #134896;
}
.cards_bg .slick-dots li button:before {
    color: #919191;
}
.cards_bg .slick-dots li button:before {
    font-size: 14px;
}
.sidebar_slide .slick-prev {
    left: -5px;
    z-index: 9;
}
.sidebar_slide .slick-next {
    right: -10px;
}
.sidebar_slide .slick-list {
    padding: 0px 15px;
}
.sidebar_slide .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0;
}
.scroll_hide {
    scrollbar-width: none;
}
.dashboard .options_classs .css-13cymwt-control {
    min-height: 28px !important;
    height: 28px !important;
    font-size: 10px !important;
  }
  .dashboard .options_classs .css-t3ipsp-control {
    min-height: 28px !important;
    height: 28px !important;
    font-size: 10px !important;
  }
  .dashboard .options_classs .css-13cymwt-control div {
    padding: 0px 2px !important;
  }
  .dashboard .options_classs .css-t3ipsp-control div {
    padding: 0px 2px !important;
  }
  .dashboard .options_classs .css-1nmdiq5-menu {
    font-size: 10px !important;
  }
  .date_picker input {
    padding: 4px 25px 4px 30px;
    width: 215px;
    font-size: 13px;
    color: #83878b;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.leaflet-container {
    z-index: 0 !important;
}
.eventmap_div .leaflet-container {
    height: calc(100vh - 120px) !important;
}
.map_div .leaflet-container {
    height: 450px !important;
}
.profile_map .leaflet-container {
    height: 450px !important;
}
.calendar_div .fc-toolbar-title {
    font-size: 18px;
}
 .dropdown-custom .options_classs .css-13cymwt-control {
    min-height: 34px !important;
    height: unset !important;
    font-size: 10px !important;
    min-width: 205px !important;
  }
  .dropdown-custom .options_classs .css-t3ipsp-control {
    min-height: 34px !important;
    height: unset !important;
    font-size: 10px !important;
    min-width: 205px !important;
  }
  .overflow-none{
    overflow: unset !important;
  }
  .menu_dropdown button {
    width: 100% !important;
}
.shadow_hide .shadow_div {
    box-shadow: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a5a5a5;
    border-radius: 10px;
  }

  /* side filter */
/* Initially hide .sideit */
.sideit {
    display: none;
  }
  
  /* Show the .sideit div when it has the 'block' class */
  .sideit.block {
    display: block;
  }
  /* .event_palce ::placeholder {
    color: #fff !important;
  } */

  .phone-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {

    height: 100px;
    overflow-y: scroll;
}

  @media(min-width:1023px){
    .sideit{
display: none !important;
    }
  }

  @media(max-width:991px){
  .eventmap_div .leaflet-container {
    height: 350px !important;
}
.map_div .leaflet-container {
    height: 350px !important;
}
  }

  @media(max-width:767px){
  .scroll_class::-webkit-scrollbar {
    display: none;
}

}

@media(max-width:639px){
.table_mobile td:before {
    content: attr(data-label);
    font-size: 11px;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    color: #323232;
    white-space: nowrap;
}
.table_mobile thead {
    display: none;
}
.table_mobile tr {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    border: none !important;
    padding: 12px;
    margin-bottom: 15px;
    background: url('assets/images/ticket_bg.jpg');
    box-shadow: 0px 0px 18px -10px;
}
.table_mobile td {
    width: 49%;
    padding: 0px !important;
}
.table_mobile td span {
    margin-left: 5px;
    color: #134896;
}
}

  @media(max-width:576px){
    .search_all .react-datepicker-popper {
        right: 130px !important;
    }
}

@media(max-width:480px){
.search_all .react-datepicker-popper {
    right: 30px !important;
}
}

@media(max-width:379px){
    .table_mobile td {
        width: 100%;
     }
    }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}